<template>


    <div id="page-user-list">
      <div class="vx-card p-6">
        <div class="vx-card__header">
          <div class="vx-card__title">
          </div>
        </div>
        <div class="flex flex-wrap items-center">
  
          <!-- ITEMS PER PAGE -->
          <div class="flex-grow">
            <div class="vx-card__title">
              <h4>รายชื่อผู้ทายผลบอลโลก</h4>         
              <br>
            </div>
        <!-- TEST -->
            
     <br>
      <div>
        <vx-card >
      <vs-table stripe  max-items="20" pagination :data="memberData" search>
      <template slot="thead">
            <vs-th>ยูสเซอร์เนม</vs-th>
            <vs-th>ลีค</vs-th>
            <vs-th>ประเทศ</vs-th>
            <!-- <vs-th>Username</vs-th> -->
            <!-- <vs-th>Password</vs-th> -->
            <vs-th>จำนวนที่ทาย</vs-th>
      </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

        
              <vs-td class="text-danger"><router-link :to="`/member/${data[indextr].username}`">{{ data[indextr].username}}</router-link></vs-td>
  
              <vs-td :data="tr.league">
                {{tr.league}}
              </vs-td>
              <vs-td :data="tr.team">
                {{tr.team}}
              </vs-td>
              <vs-td :data="tr.quota">
                {{currency(tr.quota)}}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        </vx-card>
              </div>
          </div>
        </div>
      </div>
    </div>
    
  
  </template>
  
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
  
  
import axios from '../../axios'
  
import moment from 'moment'
  
// function currencyFormatter (params) {
//   return (params.value).replace(/\d(?=(\d{3})+\.)/g, '$&,')
// }
export default {
  components: {
    vSelect,
    flatPickr
  },
  data () {
    return {
      date: moment.tz('Asia/Bangkok').format('YYYY-MM-DD'),
      events: ['onChange'],
      config: {},
      searchQuery: '',
      memberData: [],
      components: '',
      bankscb: []
      
    }
  },
  methods: {
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    currency (amount) {
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        return `${(amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }
  
    },
    selectdate () {
      axios
        .get('prediction/predictiondate')
        .then(response => (this.memberData = response.data))
      // this.gridApi = this.gridOptions.api
    }
  
  },
  watch: {
    date (newValue) {
      this.date = newValue
      this.selectdate()
    }
  },
  async mounted () {
    
    await axios
      .get('prediction/predictiondate')
      .then(response => (this.memberData = response.data))
      
  }
    
    
}
  
</script>
  